@import url('https://fonts.googleapis.com/css?family=Open+Sans:700');
/* define the fonts and colors used in the project */
:root {
  --font: 'Open Sans', sans-serif;
  --color-bg: #252525;
  --color-txt: black;
  --color-home: #F1C40F;
  --color-about: #16A085;
  --color-projects: #E74C3C;
  --color-contacts: #2980B9;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  font-family: var(--font);
  color: var(--color-txt);
 
}


.App .Navbar {
  /* display the items of the navigation bar in a grid, where the anchor link elements take the space left available by the header  */
 
}

.App .Navbar h2 {
  /* include padding instead of margin to have the header attached to the window's borders (and therefore to avoid the string included with a pseudo element from being displayed inappropriately to the left of the header) */
  padding: 0.25rem 0.75rem;
  font-size: 2.5rem;
  color: var(--color-bg);
  background: var(--color-home);
  /* position relative to absolute position the pseudo element */
  position: relative;
  /* transition for the change in background color (occurring as the nav items are pressed) */
  transition: all 0.3s ease-in-out;
}

.App .Navbar h2:before {
  /* with a pseudo element include a string, which is hidden by default below the header, to the left of the page  */
  content: 'borntofrappé';
  position: absolute;
  left: 100%;
  transform: translateX(-50vw);
  /* top to match the vertical padding */
  top: 0.25rem;
  color: var(--color-txt);
  /* transition for the transform property, to translate the string into view and to the right of the header */
  transition: all 0.3s ease-in-out;
  /* z-index to have the pseudo element below the header itself */
  z-index: -5;
}

.App .Navbar h2:hover:before {
  /* translate the pseudo element into view on hover */
  transform: translateX(10px);
}

.App .Navbar ul {
  /* display the unordered list in a single row, placing the elements at the end of the grid column */
 
  padding: 0.25rem 0.75rem;
  list-style: none;
}

.App .Navbar ul li {
  /* separate the list items more prominently */
  margin: 0 0.75rem;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.App .Navbar ul li a {
  /* reset the anchor link styles */
  color: inherit;
  text-decoration: none;
  padding: 0 0.25rem;
  letter-spacing: 0.2rem;
  /* position relative to absolute position the connected pseudo element */
  position: relative;
}

.App .Navbar ul li a:before {
  /* include a faux-border with a pseudo element  */
  content: '';
  position: absolute;
  /* by default have the faux-border hidden from view */
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  /* transition for the hover state */
  transition: all 0.3s ease-out;
}

/* include the different colors for the different anchor links */
.App .Navbar ul .col-lg-2:nth-of-type(1) a:before {
  background: var(--color-about);
}
.App .Navbar ul .col-lg-2:nth-of-type(2) a:before {
  background: var(--color-projects);
}
.App .Navbar ul .col-lg-2:nth-of-type(3) a:before {
  background: var(--color-contacts);
}
.App .Navbar ul .col-lg-2:nth-of-type(4) a:before {
  background: yellow;
}
.App .Navbar ul .col-lg-2:nth-of-type(5) a:before {
  background: pink;
}
.App .Navbar ul .col-lg-2:nth-of-type(6) a:before {
  background: purple;
}
.App .Navbar ul li a:hover:before {
  /* on hover, change the bottom property to have the faux-border stretch from the top down */
  bottom: -5px;
}

.App h1 {
  /* style the header to have the color matching the background of the header in the navbar */
  color: var(--color-home);
  text-transform: uppercase;
  text-align: center;
  margin-top: 20vh;
  font-size: calc(2rem + 1.5vw);
}

/*
for relatively smaller and smaller viewports:
- start by hiding the pseudo element responsible for the string 
- continue by removing the header bearing the "logo" as well 
*/

@media (max-width: 750px) {
  .App .Navbar h2:before {
    display: none;
  }
  
}

@media (max-width: 400px) {
  /* adjust the visible anchor link elements to show them in a single column, centered in the page */
  .App .Navbar h2 {
    display: none;
  }
  
  .App .Navbar ul li {
    margin: 0.5rem 0;
  }
}
ul{
  width:100%;
  background-color: transparent;
}

dd:before {
  /* include a faux-border with a pseudo element  */
  content: '';
  position: absolute;
  /* by default have the faux-border hidden from view */
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  /* transition for the hover state */
  transition: all 0.3s ease-out;
}
.dd:hover::before{
  background: var(--color-about);
}
.hide{
  display:none;
}
.show{
  display: block;
}
.dd-menu{
  margin-left:20px;
  text-transform: capitalize;
}
li{
  font-size: 20px;
}
@media(min-width:975px){
  .toggler{
    display: none;
  }
}