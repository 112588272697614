.btn-dark{
    color:white;
    font-size:20px;
    padding:0;
    width:250px;
}
.btn-dark:hover{
    font-size:20px;
}
.btn-dark a:link{
    margin-top: 0;
    
    color: white;
}
.vol-grid{
   

    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 100px 100px;
    text-align: center;
}
.vols{
    min-height:100vh;
    height:100%;
    background-color: black;
    background-image: url('https://media.istockphoto.com/photos/pleading-for-help-picture-id491142513?k=20&m=491142513&s=612x612&w=0&h=IWdYlKw4SjSFFYHBJlNQih2spGNA5Ev4FYFgxHFu8cc=');
    background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
    font-size: 60px;
}
.single-vol h1{
    font-size:30px;
}
.vol-grid .card{
    display: inline-block;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px #ccc;
   
    
    padding : 20px;
    margin: 20px;
    text-align: center;
    height: 600px;
    border-radius: 50%;
}
@media (max-width:1000px){
    .vol-grid{
        grid-template-columns: auto;
    }
}
.vol-grid .vol-img{
    width:300px;
    height:200px;
    border-radius: 100%;
    border-left:15px solid black;
    border-top:15px solid black;
}
.double-quote{
    height: 60px;
    width:100px;
}