#page-numbers {
    list-style: none;
    display: flex;
    color: black;
  }
 
  
  #page-numbers > li {
    margin-right: 0.3em;
    color: blue;
    user-select: none;
    cursor: pointer;

  }
 
  .commonsigns{
  
  }
  .commonsigns ul{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 30px;
  }
   .sign-img{

    width:300px;
    height:200px;
  }
 .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
  }
  
  .cards .card {
    position: relative;
    display: block;
    height: 350px;  
    width:350px;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards .card__image {      
    width: 100%;
    height: auto;
  }
  
  .cards .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(var(--curve) * 1px);    
    background-color: white;      
    transform: translateY(100%);
    transition: .2s ease-in-out;
  }
  
  .cards .card:hover .card__overlay {
    transform: translateY(0);
  }
  
  .cards .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: white;
    transform: translateY(-100%);
    transition: .2s ease-in-out;

  }
  
  .cards .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
  }
  
  .cards .card__arc path {
    fill: white;
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  }       
  
  .cards .card:hover .card__header {
    transform: translateY(0);
    background-color: white;
  }
  
  .cards .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;      
    border-radius: 50%;      
  }
  
  .cards .card__title {
    font-size: 2em;
    margin: 0 0 .3em;
    color: #6A515E;
  }
  
  .cards .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";  
    font-size: .8em; 
    color: #D7BDCA;  
  }
  
  .cards .card__status {
    font-size: .8em;
    color:black;
  }
  
  @media(max-width:1200px){
    .commonsigns ul{
      padding-left:20%;
      text-align: center;
      grid-template-columns: auto;
    }
  }
  @media(max-width:750px){
    .commonsigns ul{
      padding-left:10%;
   
    }
  }
  @media(max-width:650px){
    .commonsigns ul{
      padding-left:0;
   
    }
  }
  Particles{
    z-index: -1;
  }

  .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color:black;
    font-family: "MockFlowFont";   
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: auto;
  }    