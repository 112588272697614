img{
    width:120px;
    height:120px;
   padding: 10px;
   border: none;

}
.main{
   padding-left:30%;
   padding-right: 30%;
}