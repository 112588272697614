.keys{
  border: none;
    display:grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto;
   
    text-align: center;
}

body {
  background: black;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: rgba(255,255,255,0.0);
  border-radius: 5px;
}

@keyframes twinkle {
  0% {
    transform: scale(1, 1);
    background: rgba(255,255,255,0.0);
    animation-timing-function: ease-in;
  }
  60% {
    transform: scale(0.8, 0.8);
    background: rgba(255,255,255,1);
    animation-timing-function: ease-out;
  }
  80% {
    background: rgba(255,255,255,0.00);
    transform: scale(1, 1);
  }
  100% {
    background: rgba(255,255,255,0.0);
    transform: scale(1, 1);
  }
}



