@import url(https://fonts.googleapis.com/css?family=Open+Sans:700);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@1,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kalam);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* define the fonts and colors used in the project */
:root {
  --font: 'Open Sans', sans-serif;
  --color-bg: #252525;
  --color-txt: black;
  --color-home: #F1C40F;
  --color-about: #16A085;
  --color-projects: #E74C3C;
  --color-contacts: #2980B9;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font);
  color: black;
  color: var(--color-txt);
 
}


.App .Navbar {
  /* display the items of the navigation bar in a grid, where the anchor link elements take the space left available by the header  */
 
}

.App .Navbar h2 {
  /* include padding instead of margin to have the header attached to the window's borders (and therefore to avoid the string included with a pseudo element from being displayed inappropriately to the left of the header) */
  padding: 0.25rem 0.75rem;
  font-size: 2.5rem;
  color: #252525;
  color: var(--color-bg);
  background: #F1C40F;
  background: var(--color-home);
  /* position relative to absolute position the pseudo element */
  position: relative;
  /* transition for the change in background color (occurring as the nav items are pressed) */
  transition: all 0.3s ease-in-out;
}

.App .Navbar h2:before {
  /* with a pseudo element include a string, which is hidden by default below the header, to the left of the page  */
  content: 'borntofrappé';
  position: absolute;
  left: 100%;
  -webkit-transform: translateX(-50vw);
          transform: translateX(-50vw);
  /* top to match the vertical padding */
  top: 0.25rem;
  color: black;
  color: var(--color-txt);
  /* transition for the transform property, to translate the string into view and to the right of the header */
  transition: all 0.3s ease-in-out;
  /* z-index to have the pseudo element below the header itself */
  z-index: -5;
}

.App .Navbar h2:hover:before {
  /* translate the pseudo element into view on hover */
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}

.App .Navbar ul {
  /* display the unordered list in a single row, placing the elements at the end of the grid column */
 
  padding: 0.25rem 0.75rem;
  list-style: none;
}

.App .Navbar ul li {
  /* separate the list items more prominently */
  margin: 0 0.75rem;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.App .Navbar ul li a {
  /* reset the anchor link styles */
  color: inherit;
  text-decoration: none;
  padding: 0 0.25rem;
  letter-spacing: 0.2rem;
  /* position relative to absolute position the connected pseudo element */
  position: relative;
}

.App .Navbar ul li a:before {
  /* include a faux-border with a pseudo element  */
  content: '';
  position: absolute;
  /* by default have the faux-border hidden from view */
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  /* transition for the hover state */
  transition: all 0.3s ease-out;
}

/* include the different colors for the different anchor links */
.App .Navbar ul .col-lg-2:nth-of-type(1) a:before {
  background: #16A085;
  background: var(--color-about);
}
.App .Navbar ul .col-lg-2:nth-of-type(2) a:before {
  background: #E74C3C;
  background: var(--color-projects);
}
.App .Navbar ul .col-lg-2:nth-of-type(3) a:before {
  background: #2980B9;
  background: var(--color-contacts);
}
.App .Navbar ul .col-lg-2:nth-of-type(4) a:before {
  background: yellow;
}
.App .Navbar ul .col-lg-2:nth-of-type(5) a:before {
  background: pink;
}
.App .Navbar ul .col-lg-2:nth-of-type(6) a:before {
  background: purple;
}
.App .Navbar ul li a:hover:before {
  /* on hover, change the bottom property to have the faux-border stretch from the top down */
  bottom: -5px;
}

.App h1 {
  /* style the header to have the color matching the background of the header in the navbar */
  color: #F1C40F;
  color: var(--color-home);
  text-transform: uppercase;
  text-align: center;
  margin-top: 20vh;
  font-size: calc(2rem + 1.5vw);
}

/*
for relatively smaller and smaller viewports:
- start by hiding the pseudo element responsible for the string 
- continue by removing the header bearing the "logo" as well 
*/

@media (max-width: 750px) {
  .App .Navbar h2:before {
    display: none;
  }
  
}

@media (max-width: 400px) {
  /* adjust the visible anchor link elements to show them in a single column, centered in the page */
  .App .Navbar h2 {
    display: none;
  }
  
  .App .Navbar ul li {
    margin: 0.5rem 0;
  }
}
ul{
  width:100%;
  background-color: transparent;
}

dd:before {
  /* include a faux-border with a pseudo element  */
  content: '';
  position: absolute;
  /* by default have the faux-border hidden from view */
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  /* transition for the hover state */
  transition: all 0.3s ease-out;
}
.dd:hover::before{
  background: #16A085;
  background: var(--color-about);
}
.hide{
  display:none;
}
.show{
  display: block;
}
.dd-menu{
  margin-left:20px;
  text-transform: capitalize;
}
li{
  font-size: 20px;
}
@media(min-width:975px){
  .toggler{
    display: none;
  }
}
.prac{
    top:600
}

.card{
    border:1px solid gray;    
    
    
}
.card-grid{
    display:grid;
    grid-template-columns: auto auto auto auto auto;
}
.keys{
  border: none;
    display:grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto;
   
    text-align: center;
}

body {
  background: black;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: rgba(255,255,255,0.0);
  border-radius: 5px;
}

@-webkit-keyframes twinkle {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    background: rgba(255,255,255,0.0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    background: rgba(255,255,255,1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  80% {
    background: rgba(255,255,255,0.00);
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    background: rgba(255,255,255,0.0);
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes twinkle {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    background: rgba(255,255,255,0.0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    background: rgba(255,255,255,1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  80% {
    background: rgba(255,255,255,0.00);
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    background: rgba(255,255,255,0.0);
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}




img{
    width:120px;
    height:120px;
   padding: 10px;
   border: none;

}
.main{
   padding-left:30%;
   padding-right: 30%;
}

  * {
    font-family: "Verdana", cursive, sans-serif;
    color: #ffffff;
  }
  
  .quiz {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .quiz {
    background: #2980B9;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .app1 {
  
    margin-top:5%;
    background-color: #252d4a;
    width: 80%;
    height: 20%;
    height: -webkit-min-content;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
   
  }
  @media(max-width:700px){
    .app1{
      width: 80%;
    }
    .col-lg-4 img{
      margin-top:0px;
    }
  }
  
  .score-section {
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
   
  }
  
  .question-count {
    margin-bottom: 20px;
  }
  
  .question-count span {
    font-size: 28px;
  }
  
  .question-text {
    margin-bottom: 12px;
    font-size:20px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  button {
    width: 100%;
    font-size: 20px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  h1{
    color: #252d4a;
    text-align: center;
    font-family: 'Source Code Pro', monospace;
    font-size: 60px;
  }
  
  button:hover {
    background-color: #555e7d;
    font-size:25px;
  }
  
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }
  .col-lg-4 img{
    height:80%;
    width:80%;
    margin-top:100px;
  }
  h1{
    color: white;
  }
  #main-con{
    background-color: transparent;
  }
/* Global */

*:focus {
    outline: none;
} 

* {
  box-sizing: border-box;
}

.login {
  font-family: 'Kalam';
  height: 100%;
  min-height: 100vh;
  background-image: url('https://images.unsplash.com/photo-1524964056700-f5738231ad5e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-color: black;
}

a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #cccccc;
}



/* STRUCTURE */

.container {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  border-radius: 3px;
  border: 3px solid skyblu;
  background: black;
  padding: 0;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 0 10px #464647;
  text-align: center;
}

#formFooter {
  background: transparent;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

h2.inactive {
  color: #cccccc;
  cursor: pointer;
}

h2.active {
  color: #fff;
  border-bottom: 2px solid #5fbae9;
}

/* FORM STYLING */

input[type=button], input[type=submit], input[type="reset"]  {
  background: transparent;
  border: none;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  box-shadow: 0 0 1px skyblue;
  border-radius: 50px;
  margin: 5px 20px 40px 20px;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  box-shadow: 0 0 10px 0 skyblue;
  cursor: pointer;
  color: #fff;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

input[type=text], input[type="password"],textarea {
  background: transparent;
  color: ;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: none;
  box-shadow: 0 0 1px skyblue;
  transition: all 0.5s ease-in-out;
  border-radius: 20px;
  margin-bottom: 10%;
  
}

 

input[type=text]:focus, input[type="password"]:focus, textarea:focus {
  box-shadow: 0 0 40px skyblue;
  color: #fff;
  
}
textarea{
  height:300px;
}

       /* ANIMATIONS */

/* Fade-in-down Animation */

.fadeInDown {  
  -webkit-animation-name: fadeInDown;  
          animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
 
 50%{
  opacity: .5;
  -webkit-transform: translate3d(0, 20%, 0);
          transform: translate3d(0, 20%, 0);
 }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
 
 50%{
  opacity: .5;
  -webkit-transform: translate3d(0, 20%, 0);
          transform: translate3d(0, 20%, 0);
 }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

/* Fade-in Animation */

@-webkit-keyframes fadeIn {
 from { 
  opacity:0; 
 } 

 to {
  opacity:1; 
  } 
}

@keyframes fadeIn {
 from { 
  opacity:0; 
 } 

 to {
  opacity:1; 
  } 
}

.fadeIn {
  opacity:0;
  -webkit-animation: fadeIn ease-in 0.5s;
          animation: fadeIn ease-in 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

/* Fade-in Animation */

.underlineHover:after {
  display: block;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #fff;
}

.underlineHover:hover:after{
  width: 100%;
}


.note {
    position: relative;
    height: 100%;
    padding: 1em 1.5em;
    margin: 2em auto;
    color: #fff;
    background: #ccddff;
  }
  .note h1{
      font-size: 30px;
  }
  .all-jobs{
      padding:2% 10%;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 30px;
      margin-bottom: 3%;
  }
  .note:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 80px 80px 0;
    border-style: solid;
    border-color: #80aaff #000f;
    display: block;
    width: 0;
    box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  
  }
#page-numbers {
    list-style: none;
    display: flex;
    color: black;
  }
 
  
  #page-numbers > li {
    margin-right: 0.3em;
    color: blue;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;

  }
 
  .commonsigns{
  
  }
  .commonsigns ul{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 30px;
  }
   .sign-img{

    width:300px;
    height:200px;
  }
 .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
  }
  
  .cards .card {
    position: relative;
    display: block;
    height: 350px;  
    width:350px;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards .card__image {      
    width: 100%;
    height: auto;
  }
  
  .cards .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(var(--curve) * 1px);    
    background-color: white;      
    -webkit-transform: translateY(100%);      
            transform: translateY(100%);
    transition: .2s ease-in-out;
  }
  
  .cards .card:hover .card__overlay {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .cards .card__header {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: white;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: .2s ease-in-out;

  }
  
  .cards .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
  }
  
  .cards .card__arc path {
    fill: white;
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  }       
  
  .cards .card:hover .card__header {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-color: white;
  }
  
  .cards .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;      
    border-radius: 50%;      
  }
  
  .cards .card__title {
    font-size: 2em;
    margin: 0 0 .3em;
    color: #6A515E;
  }
  
  .cards .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";  
    font-size: .8em; 
    color: #D7BDCA;  
  }
  
  .cards .card__status {
    font-size: .8em;
    color:black;
  }
  
  @media(max-width:1200px){
    .commonsigns ul{
      padding-left:20%;
      text-align: center;
      grid-template-columns: auto;
    }
  }
  @media(max-width:750px){
    .commonsigns ul{
      padding-left:10%;
   
    }
  }
  @media(max-width:650px){
    .commonsigns ul{
      padding-left:0;
   
    }
  }
  Particles{
    z-index: -1;
  }

  .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color:black;
    font-family: "MockFlowFont";   
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: auto;
  }    
.post{
    background-color: black;
    height: 100vh;
   
    padding-top: 15vh;
}
.nextrow{
    background-color: #2c303a;
    width:70%;
    
    margin-left: 15%;
    padding:20px;
  
}
.share{
    background-color: black;
    border-radius: 5px;
    padding:10px;
}
.btn-secondary{
    background-color:#2c303a;
    border:1px solid black;
    font-size: 15px;
}
.btn-group{
    margin-left:30px;
}
.btn-secondary:hover{
    font-size:15px;
}
p{
    color:lightslategrey;
    margin-top: 20px;
    margin-bottom: 0;
}
.info{
    margin-bottom: 30px;
}
.col-lg-4 .card{
    color: white;
	background-color: black;
}
a{
    margin-top:20px;
}
.views{
    margin-top: 30px;
}
.post{
    overflow: auto;
    padding-top:0;
}
.post-header{
    background-image: url('https://o.remove.bg/downloads/3d87393e-4a47-419b-a7d7-41ef5d7d61bc/43_w1200-removebg-preview.png');
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-color: black;
    color: white;
    font-size:60px;
    font-weight: 600;
    height:400px;
}
.space-desc{
    font-size:15px;
}
.space-posts{
    margin-top:40px;
}
.space-posts .card-header{
    color:black
}
.space-posts .card{
    margin-bottom: 30px;
}
.post-header .btn-light{
    color:black;
    margin-top:20%;
    font-size: 20px;
    margin-left:250px;
}
.btn-light:hover{
    font-size:15px;
}
.btn-light{
    font-size: 15px;
}
.post-header .btn-light a:link{
    color:black;
}
@media(max-width:600px){
    .post-header .btn-light{
        margin-top:10%;
        margin-left:20px;
    }
}
.btn-dark{
    color:white;
    font-size:20px;
    padding:0;
    width:250px;
}
.btn-dark:hover{
    font-size:20px;
}
.btn-dark a:link{
    margin-top: 0;
    
    color: white;
}
.vol-grid{
   

    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 100px 100px;
    text-align: center;
}
.vols{
    min-height:100vh;
    height:100%;
    background-color: black;
    background-image: url('https://media.istockphoto.com/photos/pleading-for-help-picture-id491142513?k=20&m=491142513&s=612x612&w=0&h=IWdYlKw4SjSFFYHBJlNQih2spGNA5Ev4FYFgxHFu8cc=');
    background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
    font-size: 60px;
}
.single-vol h1{
    font-size:30px;
}
.vol-grid .card{
    display: inline-block;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px #ccc;
   
    
    padding : 20px;
    margin: 20px;
    text-align: center;
    height: 600px;
    border-radius: 50%;
}
@media (max-width:1000px){
    .vol-grid{
        grid-template-columns: auto;
    }
}
.vol-grid .vol-img{
    width:300px;
    height:200px;
    border-radius: 100%;
    border-left:15px solid black;
    border-top:15px solid black;
}
.double-quote{
    height: 60px;
    width:100px;
}

  .all-blogs{
      padding:2% 10%;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 30px;
      margin-bottom: 3%;
  }
  
.profile-pg{
    background-color: white;
    width:35%;
    margin: 10% 30%;
    text-align: center;
    border-radius: 25px;
    color:black;
}
.main-info h1{
    color: black;
    font-size: 40px;
}
.main-info p{
    font-size: 20px;
    font-style: italic;
    margin-top: 0;
}
.mastered{
    height: 200px;
    overflow: scroll;
}
.home{
    height: 100%;
    min-height: 100vh;
    background-image: url('https://i.pinimg.com/originals/63/dc/f1/63dcf1695fbb1bd8498b5718b2b024ec.jpg');
    background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-color: black;
    text-align: center;
}
.home-btn{
    padding:10px 30px;
   margin:20px;
}
.home a{
    margin-left: 200px;
    margin:1%;
}
.home h1{
    padding-top:10%;
}
