
  @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@1,700&display=swap');
  * {
    font-family: "Verdana", cursive, sans-serif;
    color: #ffffff;
  }
  
  .quiz {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .quiz {
    background: #2980B9;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .app1 {
  
    margin-top:5%;
    background-color: #252d4a;
    width: 80%;
    height: 20%;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
   
  }
  @media(max-width:700px){
    .app1{
      width: 80%;
    }
    .col-lg-4 img{
      margin-top:0px;
    }
  }
  
  .score-section {
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
   
  }
  
  .question-count {
    margin-bottom: 20px;
  }
  
  .question-count span {
    font-size: 28px;
  }
  
  .question-text {
    margin-bottom: 12px;
    font-size:20px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  button {
    width: 100%;
    font-size: 20px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  h1{
    color: #252d4a;
    text-align: center;
    font-family: 'Source Code Pro', monospace;
    font-size: 60px;
  }
  
  button:hover {
    background-color: #555e7d;
    font-size:25px;
  }
  
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }
  .col-lg-4 img{
    height:80%;
    width:80%;
    margin-top:100px;
  }
  h1{
    color: white;
  }
  #main-con{
    background-color: transparent;
  }